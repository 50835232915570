import api from './api'
import { request } from './request'

// 获取柜门贴纸模板列表
function getStickerList(pageNumber, pageSize, params = { name: '' }) {
    return request({
        url: api.machineSticker.list,
        method: 'post',
        data: { pageNumber, pageSize, params }
    })
}

// 添加柜门贴纸模板
function addStickerModel(name, sticker) {
    return request({
        url: api.machineSticker.add,
        method: 'post',
        data: { name, sticker }
    })
}

// 删除柜门贴纸模板
function deleteStickerModel(id) {
    return request({
        url: `${ api.machineSticker.delete }/${ id }`,
        method: 'post'
    })
}

// 下载柜门贴纸
function downloadSticker(codeIds, stickerId) {
    return request({
        url: api.machineSticker.download,
        method: 'post',
        responseType: 'blob',
        timeout: 60000,
        data: { codeIds, stickerId }
    })
}

export {
    getStickerList,
    addStickerModel,
    deleteStickerModel,
    downloadSticker
}