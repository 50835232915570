<template>
    <div class="sticker-btns">
        <a-button
            class="btn"
            type="primary"
            :loading="loading"
            @click="handleAdd"
            >新建模板</a-button
        >

        <a-popconfirm title="是否删除？" @confirm="handleDelete">
            <a-button class="btn" type="danger" :loading="loading">
                删除模板
            </a-button>
        </a-popconfirm>
    </div>
</template>

<script>
import { deleteStickerModel } from "network/stickerManage";

export default {
    name: "StickerBtns", // 柜门贴纸模板管理操作按钮
    props: {
        checkKeys: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    data() {
        return {
            loading: false,
        };
    },
    methods: {
        // ----------------事件处理函数------------------
        // 添加
        handleAdd() {
            this.$emit("onShowModal");
        },
        // 删除
        handleDelete() {
            if (this.checkKeys.length <= 0) return

            let id = this.checkKeys.join(',');
            this._deleteStickerModel(id)
        },

        // ----------------网络请求---------------
        // 删除
        _deleteStickerModel(id) {
            this.loading = true;

            deleteStickerModel(id)
                .then((res) => {
                    this.$message.success(res.message);
                    this.loading = false;
                    this.$emit("onSuccess");
                })
                .catch((err) => {
                    this.loading = false;
                });
        },
    },
};
</script>

<style lang="less" scoped>
.sticker-btns {
    width: 100%;
    margin: 20px auto;

    .btn {
        margin-right: 20px;
    }
}
</style>