<template>
    <div id="sticker-manage">
        <!-- 顶部面包屑 -->
        <breadcrumb :routes="breadcrumb" />

        <!-- 数据内容 -->
        <div class="sticker-data">
            <!-- 操作按钮 -->
            <sticker-btns :check-keys="checkKeys" @onShowModal="showModal = true" @onSuccess="onSuccess" />

            <!-- 列表 -->
            <div class="sticker-list-wrapper">
                <sticker-list :list="stickerList"
                         :check-keys.sync='checkKeys'
                         :loading="loading" />
            </div>

            <!-- 分页 -->
            <pagination :page.sync="page"
                        :page-size="pageSize"
                        :total-size="totalSize"
                        @onPageChange="onPageChange"
                        @onSizeChange="onSizeChange" />
        </div>

        <!-- 新建/编辑 -->
        <sticker-create-edit :show.sync="showModal" @onSuccess="onSuccess" />
    </div>
</template>

<script>
import StickerBtns from './childComps/StickerBtns'
import StickerList from './childComps/StickerList'
import StickerCreateEdit from './childComps/StickerCreateEdit'

import Breadcrumb from "components/breadcrumb/Breadcrumb";
import Pagination from "components/pagination/Pagination";

import { getStickerList } from "network/stickerManage";

export default {
    name: "StickerManage", // 柜门贴纸模板管理
    components: {
        StickerBtns,
        StickerList,
        StickerCreateEdit,
        Breadcrumb,
        Pagination,
    },
    data () {
        return {
            breadcrumb: [
                {
                    path: '',
                    breadcrumbName: "首页",
                },
                {
                    path: "/stickerManage",
                    breadcrumbName: "柜门贴纸模板管理",
                },
            ],

            page: 1,
            pageSize: 10,
            totalSize: 0,
            loading: false,

            checkKeys: [], // 选择的编码

            showModal: false, // 是否显示创建/编辑弹窗

            stickerList: [],
        };
    },
    created () {
        this._getStickerList(this.page, this.pageSize);
    },
    methods: {
        // -------------------事件处理函数-------------------
        // 切换页码
        onPageChange (page) {
            this.page = page;

            this._getStickerList(this.page, this.pageSize);
        },

        // 切换一页多少条
        onSizeChange (pageSize) {
            this.pageSize = pageSize;
            this._getStickerList(this.page, this.pageSize);
        },

        // 新建/编辑成功
        onSuccess() {
            this._getStickerList(this.page, this.pageSize)
        },

        // ---------------------网络请求-------------------
        // 获取柜门贴纸模板列表
        _getStickerList (pageNumber, pageSize) {
            this.loading = true;

            getStickerList(pageNumber, pageSize)
                .then((res) => {
                    this.totalSize = res.totalSize;
                    this.page = res.currentSize > 0 ? pageNumber : this.page;

                    const list = res.data;

                    let newList = [];

                    class Sticker {
                        constructor(obj) {
                            for (const key in obj) {
                                this[key] = obj[key];
                            }
                        }
                    }

                    for (let i = 0; i < list.length; i++) {
                        let item = list[i];
                        let sticker = new Sticker({
                            ...item,
                        });

                        for (const key in sticker) {
                            !sticker[key] &&
                                sticker[key] != 0 &&
                                (sticker[key] = "暂无数据");
                        }

                        newList.push(sticker);
                    }

                    this.loading = false;
                    this.stickerList = newList;
                })
                .catch((err) => {
                    this.loading = false;
                });
        },
    },
};
</script>

<style lang="less" scoped>
#sticker-manage {
    width: 100%;
    // height: 100%;
    position: relative;

    .sticker-data {
        margin: 0 20px 20px;
        padding: 20px;
        background-color: #fff;
        border-radius: 5px;

        .sticker-list-wrapper {
            width: 100%;
            margin: 20px auto;
        }
    }
}
</style>