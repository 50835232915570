<template>
    <div class="sticker-create-edit">
        <a-modal
            :visible="show"
            title="新建/编辑模板"
            on-ok="handleSubmit"
            @cancel="handleCancel"
        >
            <template slot="footer">
                <a-button :loading="loading" @click="handleSubmit"
                    >确定</a-button
                >
                <a-button key="back" @click="handleCancel">关闭</a-button>
            </template>

            <a-form v-bind="formItemLayout" :form="form" @submit="handleSubmit">
                <a-form-item label="贴纸模板名称">
                    <a-input
                        v-decorator="validate.name"
                        placeholder="填写名称(限20字)"
                    />
                </a-form-item>
                
                <a-form-item label="背景图片">
                    <a-upload
                        v-decorator="validate.upload"
                        name="file"
                        :action="$baseUrl + upload"
                        :headers="header"
                        method="post"
                        list-type="picture"
                    >
                        <a-button> <a-icon type="upload" /> 上传图片 </a-button>
                    </a-upload>
                </a-form-item>
            </a-form>
        </a-modal>
    </div>
</template>

<script>
import api from "network/api";

import { addStickerModel } from 'network/stickerManage'

import { getHeader } from "common/utils";

export default {
    name: "StickerCreateEdit", // 新建/编辑柜门贴纸模板
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loading: false,

            form: this.$form.createForm(this, { name: "stickerModel" }),

            formItemLayout: {
                labelCol: { span: 7 },
                wrapperCol: { span: 13 },
            },
            formSetAuth: {
                labelCol: { span: 7 },
                wrapperCol: { span: 14 },
            },

            fileList: [],
            upload: api.upload.upload,
            header: getHeader("multipart/form-data"),

            validate: {
                name: [
                    'name',
                    {
                        rules: [
                            {
                                required: true,
                                message: "填写名称(限20字)",
                            },
                        ],
                    },
                ],

                upload: [
                    "upload",
                    {
                        valuePropName: "fileList",
                        getValueFromEvent: this.normFile,
                        rules: [
                            {
                                required: true,
                                message: "请选择背景图"
                            },
                        ],
                    },
                ],
            }
        };
    },
    methods: {
        // -----------------事件处理函数----------------
        // 提交
        handleSubmit(e) {
            e.preventDefault();

            this.loading = true;

            this.form.validateFields((err, values) => {
                if (!err) {
                    let picUrl = "";
                    if (values.upload) {
                        picUrl = values.upload[0].picUrl;
                    }

                    let name = values.name,
                        sticker = `/${ picUrl }`;

                    this._addStickerModel(name, sticker)
                } else {
                    this.loading = false;
                }
            });
        },
        // 取消
        handleCancel() {
            this.form.resetFields();

            this.$emit("update:show", false);
        },

        // 图片上传返回的列表
        normFile(e) {
            let list = [];

            if (Array.isArray(e)) {
                list.push(e[e.length - 1]);
            } else if (e && e.fileList) {
                list.push(e.fileList[e.fileList.length - 1]);
            }

            for (let i = 0; i < list.length; i++) {
                if (list[i].response && list[i].response.status != 1) {
                    list[i].status = "error";
                } else if (list[i].response && list[i].response.status == 1) {
                    list[i].thumbUrl =
                        this.$fileUrl + "/" + list[i].response.data.filePath;
                    list[i].picUrl = list[i].response.data.filePath;
                }
            }

            this.fileList = list;

            return list;
        },

        // -----------------网络请求---------------
        // 新建模板
        // 创建账号
        _addStickerModel(name, sticker) {
            addStickerModel(name, sticker)
                .then((res) => {
                    this.$message.success(res.message);

                    this.$emit("onSuccess");
                    this.handleCancel();
                    this.loading = false;
                })
                .catch((err) => {
                    this.loading = false;
                });
        },
    },
};
</script>

<style lang="less" scoped>
</style>