<template>
    <div class="sticker-list" ref="listBox">
        <a-table
            :columns="columns"
            :data-source="list"
            :pagination="false"
            :loading="loading"
            :scroll="scroll"
            :row-key="record => record.id"
            :row-selection="{ selectedRowKeys: checkKeys, onChange: onSelectChange }"
        >
            
            <div slot="action" slot-scope="record">
                <a class="model-img" v-if="record" :href="($fileUrl + record)" target="_blank">
                    <img class="img" :src="$fileUrl + record" alt="" />
                </a>
            </div>
            
        </a-table>
    </div>
</template>

<script>
export default {
    name: "StickerList", // 柜门贴纸模板列表
    props: {
        list: {
            type: Array,
            default() {
                return [];
            },
        },
        checkKeys: {
            type: Array,
            default() {
                return []
            }
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            columns: [
                {
                    title: "贴纸模板名称",
                    dataIndex: "name",
                    key: "name",
                    width: 180,
                },
                {
                    title: "模板图片",
                    dataIndex: "sticker",
                    key: "sticker",
                    width: 200,
                    scopedSlots: { customRender: "action" },
                },
                {
                    title: "创建时间",
                    dataIndex: "createTime",
                    key: "createTime"
                }
            ],
            scroll: {
                x: true,
                y: 480,
            },
        };
    },
    methods: {
        // -----------------事件处理函数----------------
        onSelectChange(selectedRowKeys) {
            this.$emit('update:checkKeys', selectedRowKeys)
        },
    },
};
</script>

<style lang="less" scoped>
.sticker-list {
    width: 100%;
    // height: 100%;
    overflow: auto;

    .model-img {
        width: 150px;
        height: 150px;
        display: block;
        overflow: hidden;

        .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}
</style>